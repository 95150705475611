body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins";
}

.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* header */

.header {
  display: flex;
  align-items: center;
  padding: 20px 10vw;
  gap: 5vw;
  width: 100vw;
  overflow-x: hidden;
  box-shadow: 0px 26.693px 53.387px 0px rgba(0, 0, 0, 0.25);
}

.header > nav {
  display: flex;
  gap: 2vw;
}

.header > nav > a {
  font-size: 24px;
  font-family: Avenir;
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  text-decoration: none;
  color: black;
}

.header > nav > .active {
  color: #05b3f3;
}

/* home */

.home {
  display: flex;
  height: 670px;
  width: 100vw;
  overflow-x: hidden;
}

.home-left {
  background: #e3e3e2;
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-left > div {
  width: 600px;
}

.home-left > div > h3 {
  font-size: 50px;
  text-align: center;
}

.home-left > div > p {
  text-align: center;
  font-size: 26px;
}

.home-right {
  background-color: black;
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-right > div {
  width: 720px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-right > div > h2 {
  color: #108f4b;
  text-align: center;
  font-size: 90px;
  font-weight: 900;
  line-height: 0.8;
}

.home-right > div > h3 {
  color: #05b3f3;
  text-align: center;
  font-size: 50px;
  line-height: 2;
}

.home-right > div > p {
  color: white;
  text-align: center;
  font-size: 26px;
  margin-bottom: 30px;
}

.home-event {
  display: flex;
  width: 100vw;
  overflow-x: hidden;
}

.home-event > div {
  padding: 60px 0px;
  padding-left: 2vw;
  padding-right: 2vw;
  display: flex;
  flex-direction: column;
}

.home-event > div > h3 {
  font-size: 40px;
  color: white;
  height: 260px;
}

.home-event > div > div:nth-child(2) {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 80%;
}

.home-event > div > div:nth-child(2) > p {
  font-size: 26px;
}

.home-event > div > div:nth-child(3) {
  align-self: flex-end;
  margin-top: 100px;
}

.home-event > div > div:nth-child(3) > h3 {
  font-size: 30px;
  color: white;
  text-transform: uppercase;
}

.home-event > div > div:nth-child(3) > p {
  font-size: 22px;
  text-align: center;
}

.home-event-left {
  height: 670px;
  background-color: #108f4b;
  width: 33vw;
}

.home-event-middle {
  background: #e96c17;
  height: 670px;
  width: 33vw;
}

.home-event-right {
  grid-area: .home-event-right;
  background-color: #05b3f3;
  width: 33vw;
  height: 670px;
}

@media (max-width: 1750px) {
  .header > nav > a {
    font-size: 20px;
  }
}

@media (max-width: 1600px) {
  .header > img {
    width: 425px;
  }

  .home-event > div > h3 {
    font-size: 36px;
    margin-bottom: 20px;
  }

  .home-event > div > div:nth-child(2) {
    width: 100% !important;
  }

  .footer > img {
    width: 80%;
  }
}

@media (max-width: 1440px) {
  .home-left > div {
    width: 400px;
  }

  .home-left > div > h3 {
    font-size: 40px;
    line-height: 1.3;
    margin-bottom: 10px;
  }

  .home-left > div > p {
    font-size: 22px;
  }

  .home-right > div {
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .home-right > div > h2 {
    font-size: 70px;
  }

  .home-right > div > h3 {
    font-size: 40px;
    line-height: 1.3;
  }

  .home-right > div > p {
    font-size: 22px;
  }

  .home-right > div > img {
    width: 80%;
  }
}

@media (max-width: 1250px) {
  .header {
    padding: 20px 1vw;
    gap: 20px;
    justify-content: space-evenly;
  }

  .header > img {
    width: 350px;
  }

  .header > nav {
    gap: 10px;
  }

  .header > nav > a {
    font-size: 16px;
  }

  .home {
    height: fit-content !important;
  }

  .home-left > div > h3 {
    font-size: 30px;
  }

  .home-left > div > p {
    font-size: 16px;
    width: 80%;
    margin: auto;
  }

  .home-right > div > h2 {
    font-size: 50px;
  }

  .home-right > div > h3 {
    font-size: 30px;
    width: 80%;
  }

/* footer */

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  overflow-x: hidden;
}

.footer > h3 {
  font-size: 50px;
  line-height: 2;
}

.footer > img {
  margin: auto;
}

/* event */

.event {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .event > div {
    width: 100vw;
    height: fit-content;
  }
  .event > div h3 {
    height: fit-content;
    font-size: 20px;
  }
  .event > div p {
    font-size: 13px;
  }
  .event > div > div:nth-child(3) {
    margin-top: 20px;
  }
  .footer h3 {
    font-size: 20px;
  }
  .event img {
    width: 100px;
  }
}

@media (max-width: 768px) {
  .header > nav {
    flex-wrap: wrap;
  }

  .header > nav > a {
    font-size: 13px;
  }

  .header > img {
    width: 250px;
    margin-left: 10px;
  }

  .home {
    flex-direction: column;
  }

  .home > div {
    width: 100vw;
    padding: 30px 0;
    height: 400px;
  }

  .home-left > div > h3 {
    font-size: 22px;
    width: 80%;
    margin: auto;
  }

  .home-left > div > p {
    font-size: 13px;
    width: 80%;
    margin: auto;
    margin-top: 10px;
  }

  .home-right > div > h2 {
    font-size: 40px;
  }

  .home-right > div > h3 {
    font-size: 22px;
    margin-top: 10px;
  }

  .home-right > div > p {
    font-size: 13px;
    margin-top: 10px;
  }
}

@media (max-width: 576px) {
  .header > img {
    width: 200px;
  }

  .home > div h3 {
    width: 95%;
  }

  .home > div p {
    width: 95%;
  }
}

/* about */

.about {
  width: 1600px;
  margin: auto;
  gap: 60px;
  padding-top: 60px;
  margin-bottom: 60px;
}

/* workshop */

.workshop {
  padding: 60px;
  padding-left: 200px;
}
.workshop > h2 {
  font-size: 50px;
}
.workshop > h2:first-child {
  color: #108f4b;
}
.workshop > h3:nth-child(3) {
  font-size: 30px;
  font-family: Avenir;
}
.workshop > h3:nth-child(4) {
  color: #e96c17;
  font-size: 40px;
}
.workshop > p {
  font-size: 26px;
  font-family: Avenir;
  margin-bottom: 40px;
}
.workshop > ol {
  list-style-position: inside;
  margin-bottom: 20px;
}
.workshop > ol > li {
  font-family: Avenir;
  font-size: 26px;
}
.workshop > div {
  display: flex;
}
.workshop > div > div {
  width: 500px;
}
.workshop > div > div > h3 {
  font-size: 36px;
}
.workshop > div > div > ol {
  list-style-position: inside;
}
.workshop > div > div > ol > li {
  font-size: 26px;
  font-family: Avenir;
}
.workshop-footer {
  background-color: #108f4b;
  display: flex;
  padding: 40px 100px;
  gap: 40px;
}
.workshop-footer > div {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.workshop-footer img {
  width: 450px;
  height: 400px;
}
.workshop-footer > div > h2 {
  font-size: 45px;
}
.workshop-footer > div > p {
  font-size: 26px;
  font-family: Avenir;
}

.workshop--body,
.ED {
  padding: 60px 200px;
}

.workshop-top-headings {
  width: 100%;
}

.workshop-top-headings > h2,
.Ed-detal > h2 {
  font-size: 50px;
}
.top-heading {
  color: #108f4b;
}
.workshop-top-headings > h3 {
  font-size: 30px;
}
.free {
  color: #e96c17;
  font-size: 40px;
}
.workshop-para > p,
.workshop-para > div,
.workshop-list > div {
  margin-bottom: 40px;
}
.workshop-para > p,
.Ed-detal > p {
  font-size: 26px;
  font-family: Avenir;
}
.workshop-list {
  display: flex;
  justify-content: space-between;
}
.workshop-list > div > h3 {
  font-size: 36px;
}
li {
  font-size: 26px;
}
.ED {
  background-color: #108f4b;
}
.ED-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
ol {
  padding-left: 20px;
}

@media screen and (max-width: 1600px) {
  .workshop-top-headings > h2,
  .Ed-detal > h2 {
    font-size: 45px;
  }
  .workshop-top-headings > h3,
  .workshop-list > div > h3 {
    font-size: 28px;
  }
  li,
  .workshop-para > p,
  .Ed-detal > p {
    font-size: 26px;
  }
}

@media screen and (max-width: 1440px) {
  .workshop-top-headings > h2,
  .Ed-detal > h2 {
    font-size: 44px;
  }
  .workshop-top-headings > h3,
  .workshop-list > div > h3 {
    font-size: 25px;
  }
  li,
  .workshop-para > p,
  .Ed-detal > p {
    font-size: 24px;
  }
  .ED-img-container {
    width: 50%;
    height: 250px;
  }
}

@media screen and (max-width: 1250px) {
  .workshop-top-headings > h2,
  .Ed-detal > h2 {
    font-size: 40px;
  }
  .workshop-top-headings > h3,
  .workshop-list > div > h3 {
    font-size: 22px;
  }
  li,
  .workshop-para > p,
  .Ed-detal > p {
    font-size: 22px;
  }
  .workshop--body,
  .ED {
    padding: 60px 150px;
  }
  .ED-img-container {
    display: flex;
    justify-content: center;
    width: 50%;
    height: 250px;
  }
}

@media screen and (max-width: 1024px) {
  .workshop-top-headings > h2,
  .Ed-detal > h2 {
    font-size: 35px;
  }
  .workshop-top-headings > h3 {
    font-size: 20px;
  }
  li,
  .workshop-para > p,
  .Ed-detal > p {
    font-size: 20px;
  }

  .Ed-detal > h2 {
    text-align: center;
  }
  .ED-img-container {
    display: flex;
    justify-content: center;
    width: 50%;
    height: 250px;
  }
  .workshop--body,
  .ED {
    padding: 60px 100px;
  }
}

@media screen and (max-width: 768px) {
  .workshop-top-headings > h2,
  .Ed-detal > h2 {
    font-size: 33px;
  }
  .workshop-top-headings > h3,
  .workshop-list > div > h3 {
    font-size: 20px;
  }
  li,
  .workshop-para > p,
  .Ed-detal > p {
    font-size: 18px;
  }
  .ED-img-container {
    width: 100%;
    height: 200px;
  }
  .ED-img-container > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .Ed-detal {
    margin-top: 20px;
  }
  .ED-section {
    display: block;
  }
  .workshop--body {
    padding: 60px 50px;
  }
}

@media screen and (max-width: 576px) {
  .workshop-top-headings > h2,
  .Ed-detal > h2 {
    font-size: 30px;
  }
  .workshop-top-headings > h3,
  .workshop-list > div > h3 {
    font-size: 20px;
  }
  li,
  .workshop-para > p,
  .Ed-detal > p {
    font-size: 16px;
  }
  .workshop--body,
  .ED {
    padding: 60px 20px;
  }
}

@media screen and (max-width: 390px) {
  .workshop-top-headings > h2,
  .Ed-detal > h2 {
    font-size: 25px;
  }
  .workshop-top-headings > h3,
  .workshop-list > div > h3 {
    font-size: 18px;
  }
  li,
  .workshop-para > p,
  .Ed-detal > p {
    font-size: 12px;
  }
}

/* event */

.event {
  width: 1600px;
  margin: auto;
  display: flex;
  gap: 60px;
  padding-top: 60px;
  margin-bottom: 60px;
}
.event-left {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.event-left > div:first-child {
  background-color: #108f4b;
  padding-top: 60px;
}
.event-left > div:first-child > div:first-child {
  width: 360px;
  padding: 0 80px;
}
.event-left > div:first-child > div:first-child > img {
  width: 100%;
}
.event-left > div:first-child > div:first-child > h4 {
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  margin-top: 10px;
}
.event-left > div:first-child > div:first-child > p {
  text-align: center;
  font-size: 30px;
  font-family: Corbel;
  font-weight: 500;
}
.event-left > div:first-child > div:nth-child(2) {
  text-align: center;
  margin-top: 30px;
}
.event-left > div:first-child > div:nth-child(2) > h3 {
  color: black;
  font-size: 45px;
  font-family: "Bebas Neue";
  font-weight: 500;
}
.event-left > div:first-child > div:nth-child(2) > h3:first-child {
  color: white;
}
.event-left > div:first-child > div:nth-child(3) {
  background-color: black;
  margin-top: 50px;
  color: white;
  padding: 15px 0;
}
.event-left > div:first-child > div:nth-child(3) > h3 {
  text-align: center;
  font-size: 50px;
  font-family: "Bebas Neue";
  font-weight: 500;
}
.event-left > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.event-left > div:nth-child(2) > h3 {
  color: #4fadea;
  font-size: 40px;
  font-weight: 500;
  width: fit-content;
  border-bottom: 1px solid #4fadea;
}
.event-left > div:nth-child(2) > h5 {
  font-size: 30px;
  font-weight: 400;
}
.event-left > div:nth-child(2) > h4 {
  font-family: "Bebas Neue";
  font-size: 40px;
  font-weight: 500;
}
.event-right {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.event-right > h2 {
  font-size: 50px;
}
.event-right > p {
  font-size: 26px;
  font-family: Avenir;
}
@media (max-width: 1650px) {
  .event {
    width: 1400px;
  }
}
@media (max-width: 1450px) {
  .event {
    width: 1200px;
  }
}
@media (max-width: 1250px) {
  .event {
    width: 950px;
  }
  .event h2 {
    font-size: 30px;
  }
  .event h3 {
    font-size: 30px !important;
  }
  .event h5 {
    font-size: 26px !important;
  }
  .event p {
    font-size: 16px;
  }
}
@media (max-width: 1024px) {
  .event {
    width: 700px;
    flex-direction: column;
  }
  .event-left {
    display: flex;
    align-items: center;
    gap: 40px;
  }
}
@media (max-width: 768px) {
  .event {
    width: 500px;
  }
}
@media (max-width: 576px) {
  .event {
    width: 300px;
  }
  .event div {
    width: 300px !important;
  }
}
